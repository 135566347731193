<template>
  <div>
    <v-container>
      <div class="mt-12 text-center">
        <h3 class="text-h3 font-weight-light">The Wildwood Directory</h3>
        <p class="text-caption grey--text text--darken-1">Atlanta, Georgia</p>
      </div>
      <v-card
        dark
        color="#29526b"
        elevation="0"
        class="mx-auto mb-6"
        max-width="500"
      >
        <v-card-title
          ><vue-fontawesome :icon="['fas', 'info-circle']" class="mr-2" /> The
          directory has been updated.
          <span v-if="isLoggedIn" class="white-link font-weight-regular ml-2">
            <router-link to="/properties"> Search here. </router-link>
          </span>
        </v-card-title>
        <v-card-subtitle v-if="!isLoggedIn">
          <span class="font-weight-bold"
            >Set a new password with your email address below.</span
          >
          If you have not signed into the updated directory, please start below.
          We've made a few improvements and added some new security.
        </v-card-subtitle>
      </v-card>
      <div v-if="!isLoggedIn">
        <snackbar :attr="snackbar" />
        <v-card class="mx-auto" max-width="500">
          <v-img height="200" src="../assets/wildwood-sign.png"></v-img>
          <!-- <v-toolbar flat class="pt-4">
          <p>
            <b>The directory as been updated</b>. If you have not signed into
            the updated directory, please start below.
          </p>
        </v-toolbar> -->
          <v-toolbar flat class="pt-3">
            <v-btn :disabled="step === 1" text @click="setStep(1)">
              Sign Up
            </v-btn>
            <v-spacer></v-spacer>
            <!-- <v-btn text :disabled="step === 2" depressed @click="setStep(2)">
              Sign In
            </v-btn> -->
          </v-toolbar>
          <!-- <v-spacer></v-spacer> -->
          <!-- <v-toolbar-title> Let's see if we can find you.</v-toolbar-title> -->
          <!-- <v-spacer></v-spacer> -->
          <v-card-title
            class="text-h6 font-weight-regular justify-space-between"
          >
            <span>{{ currentTitle }}</span>
          </v-card-title>
          <v-window v-model="step">
            <v-window-item :value="1">
              <v-card-text>
                <v-form ref="lookupForm" v-model="lookupValid">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="Email..."
                    name="email"
                    autocomplete="email"
                    type="text"
                    required
                  ></v-text-field>
                </v-form>
                <span class="text-caption grey--text text--darken-1">
                  This is the email used on your profile.
                </span>
                <div class="pt-4 text-center">
                  <v-btn
                    elevation="0"
                    @click="checkEmail()"
                    :disabled="!lookupValid"
                    ><vue-fontawesome
                      :icon="['fas', 'info-circle']"
                      class="mr-1"
                    />Check Email</v-btn
                  >
                </div>
                <v-expand-transition>
                  <div v-show="emailSent">
                    <v-card elevation="0" class="mt-6">
                      <v-toolbar flat class="pt-4">
                        <v-spacer></v-spacer>
                        <v-toolbar-title
                          >Please check your inbox.</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-tooltip bottom> </v-tooltip>
                      </v-toolbar>
                      <v-card-text class="text-center">
                        <p>Password reset has been sent.</p>
                        <router-link to="/login">
                          <v-btn elevation="0" dark class="primary-btn mr-4"
                            ><vue-fontawesome
                              :icon="['fas', 'sign-in-alt']"
                              class="mr-1"
                            />Sign In</v-btn
                          >
                        </router-link>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </div>
                </v-expand-transition>
                <v-expand-transition>
                  <div v-show="emailFound">
                    <v-card elevation="0" class="mt-6">
                      <v-toolbar flat class="pt-4">
                        <v-spacer></v-spacer>
                        <v-toolbar-title
                          >Great! We found your email address.</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-tooltip bottom> </v-tooltip>
                      </v-toolbar>
                      <v-card-text class="text-center">
                        <p>Set a new secure password...</p>
                        <v-btn
                          elevation="0"
                          dark
                          class="primary-btn mr-4"
                          @click="requestRegister"
                          ><vue-fontawesome
                            :icon="['fas', 'envelope']"
                            class="mr-1"
                          />Send Email</v-btn
                        >
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </div>
                </v-expand-transition>
                <v-expand-transition>
                  <div v-show="gotoLogin">
                    <v-card elevation="0" class="mt-6">
                      <v-toolbar flat class="pt-4">
                        <v-spacer></v-spacer>
                        <v-toolbar-title
                          >You already have an active user
                          account.</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                      </v-toolbar>
                      <v-card-text class="text-center">
                        <v-btn
                          elevation="0"
                          dark
                          class="primary-btn mr-4"
                          @click="step++"
                          ><vue-fontawesome
                            :icon="['fas', 'sign-in-alt']"
                            class="mr-1"
                          />Sign In</v-btn
                        >
                        <!-- <router-link to="/login">
                        <v-btn elevation="0" dark class="primary-btn mr-4"
                          ><vue-fontawesome
                            :icon="['fas', 'sign-in-alt']"
                            class="mr-1"
                          />Sign In</v-btn
                        >
                      </router-link> -->
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </div>
                </v-expand-transition>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="2">
              <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    v-model="login.email"
                    :rules="emailRules"
                    label="Email..."
                    name="email"
                    autocomplete="email"
                    type="text"
                    required
                    ><vue-fontawesome
                      icon="user"
                      color="grey"
                      slot="prepend"
                      class="mt-1"
                  /></v-text-field>

                  <v-text-field
                    v-model="login.password"
                    v-on:keyup.enter="loginUser"
                    :rules="passwordRules"
                    id="password"
                    label="Password..."
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    required
                    ><vue-fontawesome
                      icon="lock"
                      color="grey"
                      slot="prepend"
                      class="mt-1"
                  /></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="resetPasswordPanel = !resetPasswordPanel"
                  >Reset/Forgot password</v-btn
                >
                <v-btn elevation="0" @click="reset">Reset</v-btn>
                <v-btn
                  elevation="0"
                  dark
                  class="primary-btn mr-4"
                  @click="loginUser"
                  :disabled="!valid"
                  >Login</v-btn
                >
              </v-card-actions>
            </v-window-item>
          </v-window>
        </v-card>
        <v-expand-transition>
          <div v-show="resetPasswordPanel">
            <v-card class="mx-auto my-12" max-width="500">
              <v-card-text>
                <v-form ref="forgot" v-model="forgotValid">
                  <v-text-field
                    v-model="resetEmail"
                    :rules="emailRules"
                    label="Email..."
                    name="email"
                    autocomplete="email"
                    type="text"
                    required
                    ><vue-fontawesome
                      icon="user"
                      color="grey"
                      slot="prepend"
                      class="mt-1"
                  /></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  elevation="0"
                  color="grey darken-3"
                  class="ma-2 white--text"
                  @click="requestReset"
                  :disabled="!forgotValid"
                  >Send</v-btn
                >
              </v-card-actions>
            </v-card>
          </div>
        </v-expand-transition>
      </div>
    </v-container>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'SignIn',
  components: { Snackbar },
  data: () => ({
    snackbar: {
      model: false,
      color: '',
      message: '',
      icon: ['fas', 'exclamation-triangle'],
      timeout: 3000,
    },
    emailFound: false,
    emailNotFound: false,
    emailSent: false,
    gotoLogin: false,
    propertyId: '',
    hasError: false,
    people: [],
    // loggedIn: '333',
    lookupValid: false,
    valid: false,
    firstName: '',
    lastName: '',
    step: 1,
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    resetPasswordPanel: false,
    forgotValid: false,
    login: {
      email: '',
      // email: 'michael.layne@hey.com',
      password: '',
    },
    resetEmail: '',
    passwordRules: [
      (v) => !!v || 'Password is required',
      (v) => v.length > 6 || 'Name must be more than 5 characters',
    ],
  }),
  computed: {
    isHome() {
      const path = this.$route.path
      if (path == '/') return true
      else return false
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn || false
    },
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Let's see if we can find you by email."
        // return 'Sign-up'
        case 2:
          return ''
        default:
          return 'N/A'
      }
    },
  },
  mounted() {
    const path = this.$route.path
    this.step = path == '/' ? 1 : 2
  },
  methods: {
    setStep(val) {
      this.step = val
    },
    reset() {
      this.valid = false
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    validateForm() {
      this.$refs.form.validate()
    },
    validateLookupForm() {
      this.$refs.lookupForm.validate()
    },
    resetPassword() {
      this.forgotValid = false
      this.$refs.form.reset()
    },
    resetForgotValidation() {
      this.$refs.forgot.resetValidation()
    },
    checkCurrent(dues) {
      return dues.some(
        (data) => data.year === Number(process.env.VUE_APP_CURRENT_YEAR)
      )
    },
    validateForgotForm() {
      this.$refs.forgot.validate()
    },
    async checkEmail() {
      this.emailFound = false
      this.gotoLogin = false
      this.emailSent = false
      try {
        const data = {
          email: this.email,
        }
        const requestResult = await UserService.lookup(data)
        if (!requestResult) {
          return
        }
        var i
        this.people = requestResult.data.property.people
        // console.log(this.people)
        for (i = 0; i < this.people.length; i++) {
          if (this.people[i].email == this.email) {
            this.firstName = this.people[i].firstName
            this.lastName = this.people[i].lastName
          }
        }
        // if (requestResult) {

        // }
        this.emailFound = true
        this.gotoLogin = false
        this.emailSent = false
        this.propertyId = requestResult.data.property.id
        this.snackbar.model = true
        this.snackbar.message = 'Email found.'
        this.snackbar.color = 'green'
        this.snackbar.icon = ['far', 'check']
        // console.log(requestResult)
      } catch (err) {
        if (err.response.status === 409) {
          this.emailFound = false
          this.gotoLogin = true
          this.emailSent = false
        }
        // console.log(err.response.status)
        this.snackbar.model = true
        this.snackbar.message = err.response.data.error
        // this.snackbar.message = 'Email not found.'
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
        // swal('Error', 'Something Went Wrong', 'error')
        // console.log(err.response.data)
      }
    },
    async requestRegister() {
      try {
        const data = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          property: this.propertyId,
        }
        const requestResult = await UserService.requestRegister(data)
        if (!requestResult) {
          return
        }
        this.emailFound = false
        this.gotoLogin = false
        this.emailSent = true
        this.snackbar.model = true
        this.snackbar.message = 'Success. Please check your email.'
        this.snackbar.color = 'green'
        this.snackbar.icon = ['far', 'check']
        // console.log(requestResult)
      } catch (err) {
        this.snackbar.model = true
        this.snackbar.message = err.response.data.msg
        // this.snackbar.message = 'Email not found.'
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
        // swal('Error', 'Something Went Wrong', 'error')
        // console.log(err.response.data)
      }
    },
    async loginUser() {
      if (this.valid) {
        try {
          const loginResult = await UserService.login(this.login)
          console.log(loginResult.data)
          // let response = await this.$http.post('/user/login', this.login)
          const token = loginResult.data.token
          const user = loginResult.data.userDocument
          // console.log(user.isAdmin)

          /*
            we need to see if they have current dues
          */
          const isCurrent = this.checkCurrent(
            loginResult.data.userProperty.dues
          )
          // console.log(isCurrent)
          // console.log(loginResult.data.userProperty.dues)
          // console.log('year:' + Number(process.env.VUE_APP_CURRENT_YEAR))
          if (isCurrent) {
            // if (isCurrent || user.isAdmin === true) {
            // now set need to set the agency
            localStorage.setItem('token', token)
            if (token) {
              await this.$store.dispatch('login', { user })
              this.$router.push('/properties')
            }
          } else {
            this.snackbar.model = true
            this.snackbar.message =
              'Your dues may not be updated. Please contact us.'
            this.snackbar.color = 'red'
            this.snackbar.icon = ['fas', 'exclamation-triangle']
          }
        } catch (err) {
          this.snackbar.model = true
          this.snackbar.message = 'Wrong username or password!'
          this.snackbar.color = 'red'
          this.snackbar.icon = ['fas', 'exclamation-triangle']
          // swal('Error', 'Something Went Wrong', 'error')
          // console.log(err.response.data)
        }
      }
    },
    async requestReset() {
      if (this.forgotValid) {
        try {
          const data = {
            email: this.resetEmail,
          }
          const requestResult = await UserService.requestReset(data)
          if (!requestResult) {
            return
          }
          this.snackbar.model = true
          this.snackbar.message = 'Email found. Please check your email.'
          this.snackbar.color = 'green'
          this.snackbar.icon = ['far', 'check']
          // console.log(requestResult)
        } catch (err) {
          this.snackbar.model = true
          this.snackbar.message = err.response.data.msg
          // this.snackbar.message = 'Email not found.'
          this.snackbar.color = 'red'
          this.snackbar.icon = ['fas', 'exclamation-triangle']
          // swal('Error', 'Something Went Wrong', 'error')
          // console.log(err.response.data)
        }
      }
      this.resetPasswordPanel = false
    },
  },
}
</script>
<style lang="scss" scoped>
.v-btn {
  font-weight: 500;
  letter-spacing: 0;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  text-transform: none;
}
.v-application .text-h6 {
  letter-spacing: 0;
}
.v-application .white-link a {
  color: #f3f3f3 !important;
  border-bottom: 1px dotted #cccccc;
}
</style>
